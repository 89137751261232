import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import './assets/css/var.css';
import './assets/css/reset.css';
import './assets/css/layout.scss';
import './assets/css/media.css';
import './assets/css/common.scss';

import QuickShopHover from '@/directive/quick-shop'
import CatalogMenuHover from '@/directive/catalog-menu'
import OuterClick from '@/directive/outer-click'
import LazyLoad from '@/directive/LazyLoad'
// import VueLazyload from 'vue-lazyload'

import DayFormat from '@/filters/dayFormat'
import filters from '@/filters/index'

import MetaInfo from 'vue-meta-info'
import locale from '../node_modules/element-ui/lib/locale/lang/en'
import * as utils from './utils/util';
import env from './utils/env';
import globalcomponents from './components/index';

Vue.config.productionTip = false

// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: '@/assets/pic/riajoy.png',
//   loading: '@/assets/pic/riajoy.png',
//   attempt: 1,
//   // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
//   listenEvents: [ 'scroll' ]
// })

// Vue.use(ElementUI);
Vue.use(MetaInfo)
Vue.use(globalcomponents)
Vue.use(ElementUI, { locale })
Vue.directive('quick-shop-hover', QuickShopHover)
Vue.directive('catalog-menu-hover', CatalogMenuHover)
Vue.directive('outer-click', OuterClick)
Vue.use(LazyLoad)

Vue.filter('dayFormat', DayFormat);
Vue.use(filters);

Vue.prototype.$baseUrl = env.baseUrl;
Vue.prototype.shareUrl = env.baseUrl + '/blog/share';
Vue.prototype.shareProductUrl = env.baseUrl + '/product/share';
Vue.prototype.shareCouponUrl = env.baseUrl + '/coupon/couponShare';

Vue.prototype.$utils = utils;

new Vue({
  store,
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    // document.dispatchEvent(new Event("custom-render-trigger"));
  }
}).$mount('#app')
// 禁用右键
document.addEventListener('contextmenu', function(e) {
  e.preventDefault();
});